import * as Yup from 'yup';

export const relatorioRecbimentoCondominoSchema = Yup.object().shape({
  dataInicial: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  dataFinal: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
});

export const relReciboFaturas = Yup.object().shape({
  dataInicial: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  dataFinal: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  layout: Yup.string()
    .required('Layout Obrigatório.')
    .nullable(),
});

export const relRecebimentoTaxas = Yup.object().shape({
  dataInicial: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  dataFinal: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  metodoRelatorio: Yup.string()
    .required('Tipo Obrigatório.')
    .nullable(),
});

export const relRelacaoBoletoGerados = Yup.object().shape({
  periodoInicial: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  periodoFinal: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  filtro: Yup.string()
    .required('Filtro Obrigatório.')
    .nullable(),
});

export const relMovimentacaoFinanceira = Yup.object().shape({
  periodo: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
});

export const relEmissaoBoleto = Yup.object().shape({
  periodoInicial: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),

  periodoFinal: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
});

export const relCobranca = Yup.object().shape({
  dataVencimentoFinal: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),

  dataCalculo: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
});

export const relLeituraConsumo = Yup.object().shape({
  unidadeId: Yup.string()
    .nullable()
    .required('Unidade Obrigatória'),
  tipoDoConsumo: Yup.string()
    .nullable()
    .required('Tipo do Consumo Obrigatório'),
  periodoInicial: Yup.string()
    .nullable()
    .required('Período Inicial Obrigatório'),
  periodoFinal: Yup.string()
    .nullable()
    .required('Período Final Obrigatório'),
});

export const relExtratoConta = Yup.object().shape({
  dataInicial: Yup.string()
    .nullable()
    .required('Data Inicial Obrigatória!'),
  dataFinal: Yup.string()
    .nullable()
    .required('Data Final Obrigatória!'),
  conta: Yup.string()
    .nullable()
    .required('Campo obrigatório!'),
});

export const relDemonstrativoDespesasSchema = Yup.object().shape({
  periodo: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
});

export const relDemonstrativoDespesasRateadas = Yup.object().shape({
  periodo: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  modeloLayout: Yup.string()
    .required('Campo Obrigatório.')
    .nullable(),
});

export const relRateioContasSchema = Yup.object().shape({
  periodo: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
});

export const relBorderoArrecadacaoSchema = Yup.object().shape({
  periodo: Yup.string()
    .nullable()
    .required('Período Obrigatório.'),
  unidade: Yup.string()
    .nullable()
    .required('Unidade Obrigatório.'),
  titulo: Yup.string()
    .nullable()
    .required('Título Obrigatório.'),
});

export const relFaturasEnviadasPorEmailSchema = Yup.object().shape({
  PeriodoInicial: Yup.date().required('data Inicial Obrigatória!'),
  PeriodoFinal: Yup.date().required('data Final Obrigatória!'),
});

export const relDemonstrativoSaldoConta = Yup.object().shape({
  contaId: Yup.string().required('Conta Obrigatória'),
  periodoInicial: Yup.string().required('Período Inicial Obrigatório'),
  periodoFinal: Yup.string().required('Período Final Obrigatório'),
});

export const validacaoObservacao = Yup.object().shape({
  dataObservacao: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  tipoRelatorio: Yup.string()
    .required('Campo Obrigatório.')
    .nullable(),
});

export const filtroObservacao = Yup.object().shape({
  periodoInicial: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  periodoFinal: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  tipoRelatorio: Yup.string()
    .required('Campo Obrigatório.')
    .nullable(),
});

export const relatorioConciliacaoContaSchema = Yup.object().shape({
  periodo: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  id_ContaRecursoFinanceiro: Yup.string()
    .required('Conta Obrigatória')
    .nullable(),
});

export const relatorioAcordoSchema = Yup.object().shape({
  dataInicioPeriodo: Yup.string()
    .required(' Período Obrigatório.')
    .nullable(),
  dataFimPeriodo: Yup.string()
    .required(' Período Obrigatório.')
    .nullable(),
});

export const relaPrevisaoUnidades = Yup.object().shape({
  periodo: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  idUnidade: Yup.string()
    .required('Campo Obrigatório.')
    .nullable(),
});

export const relatorioListaPresencasSchema = Yup.object().shape({
  titulo: Yup.string().required('Título Obrigatório.'),
});

export const relBalanceteSintetico = Yup.object().shape({
  periodoInicial: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  periodoFinal: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
});

export const relDeUnidades = Yup.object().shape({
  condomino_id: Yup.string()
    .nullable()
    .required('Campo Obrigatório'),
  proprietario_id: Yup.string()
    .nullable()
    .required('Campo Obrigatório'),
});
export const relContasRateadas = Yup.object().shape({
  periodo: Yup.string()
    .nullable()
    .required('Campo Obrigatório'),
});
export const relDeclaracaoQuitacaoCondominal = Yup.object().shape({
  ultimaDataVencimento: Yup.string()
    .nullable()
    .required('Período Obrigatório'),
});

export const relFaturasAcordadas = Yup.object().shape({
  unidadeId: Yup.string()
    .required('Campo Obrigatório.')
    .nullable(),
});
export const filtroLogs = Yup.object().shape({
  monthYear: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  Descricao: Yup.string()
    .required('Campo Obrigatório.')
    .nullable(),
});
export const relatorioRecebimentoCondominos = Yup.object().shape({
  dataInicial: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  dataFinal: Yup.string()
    .required('Período Obrigatório.')
    .nullable(),
  contasSelecionadas: Yup.string()
    .required('Campo Obrigatório.')
    .nullable(),
});