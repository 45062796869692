import axios from 'axios';
import { upperCase } from 'lodash';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { cpfCnpj, newGuid } from '../utils/Functions';
import {
  sendErroRequest,
  createSuccessNotification,
  createInfoNotification,
} from '../utils/Notification';
import { validaEmail } from '../utils/validations/validations';
import { getEstados } from './comumActions';
import { adicionarItemRelatorio } from './gerenciadorRelatorioActions';

const { hashHistory } = hash;

export const getCondominosSuccess = (response) => ({
  type: a.CDM_GET_CONDOMINOS,
  payload: response,
});

export const setListaCondominosEditar = (response) => ({
  type: a.CDM_SET_CONDOMINOS_EDITAR,
  payload: response,
});

export const setMascaraInscricao = (dados) => ({
  type: a.CDM_SET_MASCARA_INSCRICAO,
  payload: dados,
});

export const setListaEmails = (listaEmails) => ({
  type: a.CDM_SET_LISTA_EMAILS,
  payload: listaEmails,
});

const setCidades = (response) => ({
  type: a.COMUM_GET_CIDADES,
  payload: response,
});

export const setCondominoById = (condomino) => ({
  type: a.CDM_GET_CONDOMINO_BY_ID,
  payload: condomino,
});

export const processandoUploadDoCondomino = (bool) => ({
  type: a.CDM_PROCESSANDO_UPLOAD_CONDOMINO,
  payload: bool,
});

export const getCondominos = () => async (dispatch) => {
  try {
    dispatch(processandoCondomino(true));
    const { data } = await laris.get(`/condominos`);
    const condominos = data.map((c) => ({
      ...c,
      condominoEditado: false,
      listaEmails: c.emails.map((e) => e.descricao).join(', '),
    }));

    dispatch(getCondominosSuccess(condominos));
    dispatch(processandoCondomino(false));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Condôminos!');
  }
};

export const getMascaraInscricao = (tipo) => (dispatch) => {
  let dados = { mascara: '' };
  if (tipo) {
    if (tipo === 1) {
      dados = {
        mascara: '99.999.999/9999-99',
      };
    } else if (tipo === 2) {
      dados = {
        mascara: '999.999.999-99',
      };
    }
  }
  dispatch(setMascaraInscricao(dados));
};

const processandoCondomino = (bool) => ({
  type: a.CDM_PROCESSANDO_CONDOMINO,
  payload: bool,
});

export const setProprietarios = (response) => ({
  type: a.CDM_GET_PROPRIETARIOS,
  payload: response,
});
export const deleteCondomino = (id) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Você tem certeza que deseja deletar esse item? ',
      html: '<span style="color:#f44336"> </br>A exclusão deste condômino impactará nas unidades que ainda não têm remessa gerada.</br> Será necessário preencher com outro condômino a unidade que este pertencia. ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Deletar',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      await laris.delete(`/condominos/${id}`);
      dispatch(getCondominos('', 0));
      createSuccessNotification('Condômino excluído com sucesso!');
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao excluir o Condomino!');
  }
};

export const getCondominoById = (id) => async (dispatch) => {
  dispatch(processandoCondomino(true));

  try {
    const { data } = await laris.get(`/condominos/${id}`);

    const listaEmails = data.emails.map((e) => e.descricao).join(', ');
    data.listaEmails = listaEmails;

    dispatch(setListaEmails(data.emails));
    dispatch(setCondominoById(data));
    dispatch(processandoCondomino(false));
    dispatch(getMascaraInscricao(data.tipoInscricao));
    if (data.cidade) {
      dispatch(buscarCidadePorIdUf(data.cidade));
    }
    dispatch(processandoCondomino(false));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao buscar a condômino selecionada!');
    dispatch(processandoCondomino(false));
  }
};

const buscarCidadePorIdUf = (id) => async (dispatch) => {
  try {
    if (id) {
      const { data } = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id.uf.id}/municipios/`,
      );

      dispatch(setCidades(data));
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar as cidades do estado selecionado!');
  }
};

export const postCondomino = (values, listaEmails) => async (dispatch) => {
  try {
    if (listaEmails.length === 0)
      return createInfoNotification(
        'É necessário inserir pelo menos um email no condomínio para concluir o cadastro dos parâmetros!',
      );
    const telefoneSemMascara = values.telefoneFixo?.replace(/[^\d]+/g, '');
    const celularSemMascara = values.telefoneCelular?.replace(/[^\d]+/g, '');

    const cond = {
      id: values.id,
      nome: values.nome,
      tipoInscricao: values.tipoInscricao,
      inscricao: values.inscricao.replace(/[^\d]+/g, ''),
      endereco: values.endereco,
      bairro: values.bairro,
      cep: values.cep,
      cidade: values.cidade,

      telefoneFixo: telefoneSemMascara !== '' ? values.telefoneFixo : '',
      telefoneCelular: celularSemMascara !== '' ? values.telefoneCelular : '',
      agruparMinhasUnidadesNoMesmoBoleto: values.agruparMinhasUnidadesNoMesmoBoleto,

      ehProprietario: values.ehProprietario,
      protestaTitulos: values.protestaTitulos,
      diasParaProtesto: values.diasParaProtesto,
      emails: listaEmails,
      condominoEditado: false,
    };

    await laris.post(`/condominos`, cond);
    createSuccessNotification('Condômino adicionado com sucesso!');
    dispatch(setListaEmails([]));
    dispatch(getCondominos());
    hashHistory.push('/condominos');
  } catch (error) {
    sendErroRequest(error, 'Erro ao Adicionar o Condômino');
  }
};

export const addEmailCondomino = (values, listaEmails) => async (dispatch) => {
  try {
    if (!validaEmail(values)) {
      return createInfoNotification('Erro ao tentar adicionar um email. Email inválido!');
    }
    const novoEmail = {
      id: newGuid(),
      descricao: values,
      emailPrincipal: false,
    };

    dispatch(setListaEmails([...listaEmails, novoEmail]));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar as cidades do estado selecionado!');
  }
};

export const getProrietarios = () => async (dispatch) => {
  try {
    dispatch(processandoCondomino(true));
    const { data } = await laris.get(`/condominos/getProprietarios`);
    dispatch(setProprietarios(data));
    dispatch(processandoCondomino(false));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao carregar os proprietários!');
  }
};

export const handleEmailPrincipal = (idEmail, value, listaEmails) => async (dispatch) => {
  const novaLista = listaEmails.map((e) => {
    if (e.id === idEmail) {
      return { ...e, emailPrincipal: value };
    }
    return { ...e, emailPrincipal: false };
  });

  return dispatch(setListaEmails(novaLista));
};

export const removeEmailCondomino = (email, listaEmails) => async (dispatch) => {
  const novaLista = await listaEmails.filter((item) => item.id !== email.id);

  dispatch(setListaEmails(novaLista));
};

export const putCondomino = (values, listaEmails, condominos) => async (dispatch) => {
  try {
    if (listaEmails.length === 0)
      return createInfoNotification(
        'É necessário inserir pelo menos um email no condomínio para concluir o cadastro dos parâmetros!',
      );
    const telefoneSemMascara = values.telefoneFixo?.replace(/[^\d]+/g, '');
    const celularSemMascara = values.telefoneCelular?.replace(/[^\d]+/g, '');
    const list = listaEmails.map((e) => e.descricao).join(', ');
    const cond = {
      id: values.id,
      nome: values.nome,
      tipoInscricao: values.tipoInscricao,
      inscricao: values.inscricao.replace(/[^\d]+/g, ''),
      endereco: values.endereco,
      bairro: values.bairro,
      cep: values.cep,
      cidade: values.cidade,
      telefoneFixo: telefoneSemMascara !== '' ? values.telefoneFixo : '',
      telefoneCelular: celularSemMascara !== '' ? values.telefoneCelular : '',
      agruparMinhasUnidadesNoMesmoBoleto: values.agruparMinhasUnidadesNoMesmoBoleto,

      ehProprietario: values.ehProprietario,
      protestaTitulos: values.protestaTitulos,
      diasParaProtesto: values.diasParaProtesto,
      emails: listaEmails,
      condominoEditado: true,
      listaEmails: list,
    };

    dispatch(processandoCondomino(true));

    await laris.put(`/condominos`, cond);

    dispatch(processandoCondomino(false));
    createSuccessNotification('Condômino Editado com sucesso!');

    dispatch(setListaEmails([]));

    const lista = condominos.map((c) => {
      if (c.id === cond.id) return cond;
      return c;
    });
    dispatch(getCondominosSuccess(lista));

    hashHistory.push('/condominos');
  } catch (error) {
    dispatch(processandoCondomino(false));
    sendErroRequest(error, 'Ocorreu um erro ao alterar a condômino!');
  }
};
export const limparDadosCondominos = () => async (dispatch) => {
  dispatch(setListaEmails([]));
  dispatch(setCondominoById(null));
  dispatch(processandoCondomino(false));
  dispatch(getMascaraInscricao(null));
};
export const postExportarCondominos = () => async (dispatch) => {
  try {
    createInfoNotification('Solicitação feita com sucesso! Aguarde a planilha.');
    const { data } = await laris.get('/condominos/export-planilha-condominos');
    const item = {
      dataEmissao: new Date(),
      nome: 'Planilha_Condominos_Unidades',
      url: data.retorno,
    };
    dispatch(adicionarItemRelatorio(item));
  } catch (error) {
    sendErroRequest(error, 'Erro ao fazer ao exportar a planilha de contas!');
  }
};

export const getImportarPlanilhaCondominos = (resp) => async (dispatch) => {
  try {
    dispatch(processandoUploadDoCondomino(true));
    dispatch(processandoCondomino(true));

    dispatch(getEstados());

    const arrayrows = resp.rows.slice(5);
    const novoArray = arrayrows.filter((li) => li.length > 0 && li.indexOf('limite') < 0);
    const quantidadeLinha = novoArray.length;

    const listaCondominosImportados = [];

    for (let i = 0; i < quantidadeLinha; i += 1) {
      const row = arrayrows[i];

      const validaCpf = cpfCnpj(row[9]);

      if (!validaCpf) {
        dispatch(processandoUploadDoCondomino(false));
        dispatch(processandoCondomino(false));
        return createInfoNotification('Erro ao fazer o upload da planilha ou CPF inválidos');
      }

      const condomino = {
        nome: row[1],
        endereco: row[2],
        bairro: row[3],
        cidade: row[4],
        uf: row[5],
        cep: row[6],
        telefoneCelular: row[7],
        tipoInscricao: upperCase(row[8]).trim() === 'CNPJ' ? 1 : 2,
        inscricao: row[9]
          .toString()
          .replace('.', '')
          .replace('.', '')
          .replace('/', '')
          .replace('-', ''),
        emails: row[10].split(';'),
      };

      listaCondominosImportados.push(condomino);
    }

    await laris.post(`/condominos/upload-planilha-condomino`, listaCondominosImportados);

    dispatch(processandoUploadDoCondomino(false));
    dispatch(processandoCondomino(false));
    createSuccessNotification('Condominos importadas com Sucesso da planilha!');
    dispatch(getCondominos());
  } catch (error) {
    dispatch(processandoUploadDoCondomino(false));
    dispatch(processandoCondomino(false));
    sendErroRequest(error, 'Erro ao fazer o upload da planilha ou dados incorretos!');
  }
};

export const putAlteracaoColetiva = (condominos) => async (dispatch) => {
  try {
    dispatch(processandoCondomino(true));
    const dados = {
      Condominos: condominos,
    };
    await laris.put(`/condominos/atualiza-protesto-coletivo`, dados);

    dispatch(processandoCondomino(false));
    createSuccessNotification('Alteração Coletiva realizada com sucesso!');
  } catch (error) {
    dispatch(processandoCondomino(false));
    sendErroRequest(error, 'Erro ao alterar os condôminos!');
  }
};